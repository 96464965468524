import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Accordion from "../website/accordion"

class PrivacyAccordion extends React.Component {

    render () {    

    return (
      <>
        {this.props.accordions.map((accordion) => {
          return <Accordion key={accordion.id}
                title={accordion.accordion_title}
                description={accordion.accordion_description}
            />
        })}
      </>
    )
  }
}

export default () => (
  <StaticQuery
        query={graphql`
            query {
                strapiPrivacy {
                    privacy_accordion {
                        id
                        accordion_title
                        accordion_description
                    }
                }            
            }
    `}
    render={(data) => (
      <PrivacyAccordion accordions={data.strapiPrivacy.privacy_accordion} />
    )}
  />
)
