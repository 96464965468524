import React from 'react'
import { graphql } from "gatsby"
import { Container, Grid } from "@material-ui/core"

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Accordion from "../components/accordions/privacy"
import Parallax from "../components/website/Parallax"
import Footer from "../images/calculators/calculators-cta.jpg"

export default function Privacy({data}) {
    return (  
        <Layout>
        <SEO title="Privacy Policy" />    
            {data.allStrapiPrivacy.edges.map(({ node }) => (
            <>
            <Container className="my-40">
                <Grid container className="flex justify-center">
                    <Grid item md={10}>
                        <div className="md:w-2/4 mb-20" key={node.Privacy_intro.id}>
                            <h1 className="text-blue-800 text-5xl">{node.Privacy_intro.intro_title}</h1>
                            <p>{node.Privacy_intro.intro_description}</p>
                        </div>
                        <div className="privacy-accordion">
                            <Accordion />
                        </div>
                    </Grid>
                </Grid>
            </Container>
            </>
            
            ))}

            <Parallax 
              title="Need help? Talk to us today. "
              link="/contact"
              button="contact us"
              order="right"
              image={Footer}
            />
        </Layout>
    )
}

export const query = graphql`
query Privacy {
    allStrapiPrivacy {
      edges {
        node {
          Privacy_intro {
            id
            intro_title
            intro_description
          }
        }
      }
    }
  }  
`